<template>
  <div class="popup-detail">
    <el-dialog
      :title="todo == 'add' ? '【新增】请假申请' : (todo == 'edit' ? '【审核】请假申请' : '【查看】请假申请')"
      width="50%"
      :close-on-click-modal="false"
      :visible.sync="visible">
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm" size="small" label-width="120px">
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="请假类型" prop="leaveType">
              <el-select class="item-choose" v-model="dataForm.leaveType" :disabled="isView" style="width: 100%;"
                         filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in dictTypeMap.leave_type"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="申请时间" prop="requestTime">
              <el-date-picker v-model="dataForm.requestTime"
                              type="datetime"
                              default-time="12:00:00"
                              format="yyyy-MM-dd HH:mm"
                              value-format="yyyy-MM-dd HH:mm"
                              placeholder="选择时间"
                              :disabled="isView"
                              style="width: 100%">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="申请人" prop="driverId">
              <el-select class="item-choose"
                         v-model="dataForm.driverId"
                         :disabled="isView || todo === 'edit'"
                         @change="changeDriver()"
                         style="width: 100%;"
                         filterable clearable placeholder="请选择">
                <el-option
                  v-for="item in driverList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="contactPhone">
              <el-input v-model="dataForm.contactPhone" :disabled="true"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="请假开始时间" prop="leaveStart">
              <el-date-picker v-model="dataForm.leaveStart"
                              type="datetime"
                              default-time="12:00:00"
                              format="yyyy-MM-dd HH:mm"
                              value-format="yyyy-MM-dd HH:mm"
                              placeholder="选择时间"
                              :disabled="isView"
                              style="width: 100%">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="请假截止时间" prop="leaveEnd">
              <el-date-picker v-model="dataForm.leaveEnd"
                              type="datetime"
                              default-time="12:00:00"
                              format="yyyy-MM-dd HH:mm"
                              value-format="yyyy-MM-dd HH:mm"
                              placeholder="选择时间"
                              :disabled="isView"
                              style="width: 100%">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="请假天数" prop="leaveDays">
              <el-input v-model="dataForm.leaveDays" :disabled="false"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="请假事由" prop="leaveDesc">
              <el-input :rows="4" :disabled="isView" type="textarea" v-model="dataForm.leaveDesc" placeholder="请假事由"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="12">
            <el-form-item label="附件">
              <al-upload :fileData="fileList" :fileUrl="uploadUrl" :isView="isView" :format="'file'" :type="'leave'"
                         :limit="2" :businessType="5005" @getFileData="getFileData"></al-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <div v-if="todo === 'audit'">
          <h3 style="margin-left: 20px">审核信息</h3>
          <el-row :gutter="50">
            <el-col :span="24">
              <el-form-item label="审核意见" prop="auditStatus">
                <el-radio-group v-model="dataForm.auditStatus">
                  <el-radio :label="1">审核通过</el-radio>
                  <el-radio :label="2">审批不通过</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="24">
              <el-form-item label="审核意见" prop="auditDesc">
                <el-input :rows="4" :disabled="todo === 'view'" type="textarea" v-model="dataForm.auditDesc"
                          placeholder="审核意见"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="12">
              <el-form-item label="审核人" prop="auditName">
                <el-input v-model="dataForm.auditName" :disabled="todo === 'view'"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="审核时间" prop="auditTime">
                <el-date-picker v-model="dataForm.auditTime"
                                type="datetime"
                                default-time="12:00:00"
                                format="yyyy-MM-dd HH:mm"
                                value-format="yyyy-MM-dd HH:mm"
                                placeholder="选择时间"
                                :disabled="todo === 'view'"
                                style="width: 100%">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div v-if="todo !== 'audit' && this.dataForm.id">
          <h3 style="margin-left: 20px">流程</h3>
          <el-row :gutter="50">
            <el-col :span="12">
              <el-form-item label="审核意见">
                <el-input autosize :disabled="true" type="textarea" v-model="dataForm.auditDesc" />
              </el-form-item>
            </el-col>
          </el-row>
          <hr style="border: none;border-top: 0.8px solid #ccc;">
          <el-row style="margin-left: 50px;">
            <el-col :span="8"><div class="grid-content bg-purple"></div></el-col>
            <el-col :span="8">
              <div class="grid-content bg-purple-light">
                <el-steps :active="stepsCurrent" finish-status="success">
                  <el-step :title="'申请' + '(' + dataForm.driverName + ')'" :description="formattedDateTime(dataForm.createTime)">
                    <template #description>
                      <span>{{ '申请日期: ' + formattedDateTime(dataForm.createTime) }}</span>
                    </template>
                  </el-step>
                  <el-step :status="dataForm.auditStatus == 2 || dataForm.auditStatus == 3 ? 'error' : dataForm.auditStatus == 0 ? 'wait' : 'success'">
                    <template #title >
                      <span v-if="dataForm.auditStatus == 0">{{ '审批()' }}</span>
                      <span v-if="dataForm.auditStatus == 1 || dataForm.auditStatus == 2">{{ '审批' + '(' + (dataForm.auditName == undefined ? '' : dataForm.auditName) + ')' }}</span>
                      <span v-if="dataForm.auditStatus == 3">撤回</span>
                    </template>
                    <template #description>
                      <span v-if="dataForm.auditStatus == 1 || dataForm.auditStatus == 2">{{ '审核日期: ' + formattedDateTime(dataForm.auditTime) }}</span>
                    </template>
                  </el-step>
                </el-steps>
              </div>
            </el-col>
            <el-col :span="8"><div class="grid-content bg-purple"></div></el-col>
          </el-row>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()" v-if="todo != 'view'">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {formattedDateTime} from "@/utils/common";
export default {
  data () {
    return {
      stepsCurrent: 1,
      visible: false,
      dataForm: {
        id: null,
        driverId: null,
        contactPhone: null,
        leaveType: null,
        requestTime: null,
        leaveStart: null,
        leaveEnd: null,
        leaveDays: null,
        leaveDesc: null,
        auditDesc: null,
        auditStatus: null,
        auditName: null,
        auditTime: null,
        status: 0
      },
      uploadUrl: '',
      driverList: [],
      fileList: [],
      todo: '',
      dataRule: {
        driverId: this._Valid.init(['null']),
        leaveType: this._Valid.init(['null']),
        leaveStart: this._Valid.init(['null']),
        leaveEnd: this._Valid.init(['null'])
      }
    }
  },
  computed: {
    dictTypeMap: {
      get () {
        return this.$store.state.common.dictTypeMap
      }
    },
    isView () {
      return this.todo === 'view' || this.todo === 'audit'
    }
  },
  methods: {
    formattedDateTime,
    clearFileList () {
      this.uploadUrl = this.$http.adornUrl('/upload/file')
      this.accessToken = this.$cookie.get('TmsToken')
      this.fileList = []
    },
    getFileData (data) {
      this.fileList = data.fileList
      this.dataForm.enclosureInfoList = []
      for (let i = 0; i < this.fileList.length; i++) {
        this.dataForm.enclosureInfoList.push(this.fileList[i])
      }
    },
    initFileList (data) {
      let infoList = data.enclosureInfoList
      if (infoList) {
        this.dataForm.enclosureInfoList = data.enclosureInfoList
        // 不直接赋值的原因是引用的是一个数组，删除会有问题(表面删除了实际数据没有)
        for (let i = 0; i < infoList.length; i++) {
          this.fileList.push({
            id: infoList[i].id,
            name: infoList[i].name,
            url: infoList[i].url,
            businessType: infoList[i].businessType,
            status: infoList[i].status,
            uid: infoList[i].uid
          })
        }
      }
    },
    init (todo, id) {
      this.dataForm.id = id
      this.todo = todo
      this.clearFileList()
      this.$http({
        url: this.$http.adornUrl('/driver/findSelfList'),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.driverList = data
      }).then(() => {
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
        })
        this.visible = true
      }).then(() => {
        if (this.dataForm.id) {
          this.$http({
            url: this.$http.adornUrl(`/driverLeave/${this.dataForm.id}`),
            method: 'get',
            params: this.$http.adornParams()
          }).then(({ data }) => {
            if (data) {
              this.dataForm.driverId = data.driverId
              this.dataForm.driverName = data.driverName
              this.dataForm.leaveType = data.leaveType
              this.dataForm.requestTime = data.requestTime
              this.dataForm.createTime = data.createTime
              this.dataForm.leaveStart = data.leaveStart
              this.dataForm.leaveEnd = data.leaveEnd
              this.dataForm.leaveDays = data.leaveDays
              this.dataForm.auditStatus = data.auditStatus
              this.dataForm.leaveDesc = data.leaveDesc
              this.dataForm.auditDesc = data.auditDesc
              this.dataForm.auditName = data.auditName
              this.dataForm.auditTime = data.auditTime

              if (this.dataForm.auditStatus != 0) {
                this.stepsCurrent = 2
              }

              this.initFileList(data)
              this.changeDriver()
            }
          })
        }
      })
    },
    /**
     * 改变选择的司机
     */
    changeDriver () {
      this.dataForm.contactPhone = null
      if (this.dataForm.driverId) {
        for (let i = 0; i < this.driverList.length; i++) {
          if (this.driverList[i].id === this.dataForm.driverId) {
            this.dataForm.contactPhone = this.driverList[i].contactPhone
            break
          }
        }
      }
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let methodName = 'add'
          let method = 'put'
          if (this.todo === 'add') {
            methodName = 'add'
          } else if (this.todo === 'edit') {
            methodName = 'update'
            method = 'post'
          } else if (this.todo === 'audit') {
            methodName = 'audit'
            method = 'post'
          }
          this.$http({
            url: this.$http.adornUrl(`/driverLeave/` + methodName),
            method: method,
            data: this.$http.adornData(this.dataForm)
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    },
  }
}
</script>
